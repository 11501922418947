import type { INTEGRATION_PARTNERS } from 'config/types';

import type { Cards } from './cards.types';
import type { ServiceLine } from './service.types';
import type { TimeSlots } from './timeSlot.types';

export enum OrderStatus {
  RECEIVED = 'ORDER_RECEIVED',
  PICKUP_ASSIGNED = 'PICKUP_ASSIGNED',
  PICKUP_IN_PROGRESS = 'PICKUP_IN_PROGRESS',
  PICKUP_FAILED = 'PICKUP_FAILED',
  PICKUP_COMPLETE = 'PICKUP_COMPLETE',
  ITEMS_IN_FACILITY = 'ITEMS_IN_FACILITY',
  ITEMS_SORTED = 'ITEMS_SORTED',
  ITEMS_PENDING_APPROVAL = 'ITEMS_PENDING_APPROVAL',
  DROPOFF_TODAY = 'DROPOFF_TODAY',
  DROPOFF_ASSIGNED = 'DROPOFF_ASSIGNED',
  DROPOFF_COMPLETED = 'DROPOFF_COMPLETED',
  DROPOFF_IN_PROGRESS = 'DROPOFF_IN_PROGRESS',
  DROPOFF_FAILED = 'DROPOFF_FAILED',
  COMPLETED = 'ORDER_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELLED = 'ORDER_CANCELLED',
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  // new cases
  ORDER_DELIVERY = 'ORDER_DELIVERY',
  ORDER_PARTIALLY_DELIVERED = 'ORDER_PARTIALLY_DELIVERED',
  PENDING_ITEMS_DELIVERY = 'PENDING_ITEMS_DELIVERY',
  ORDER_COLLECTION = 'ORDER_COLLECTION',
  EXTRA_ITEM_COLLECTION = 'EXTRA_ITEM_COLLECTION',
  ORDER_PARTIALLY_DELIVERED_FAILED = 'ORDER_PARTIALLY_DELIVERED_FAILED',
  PENDING_ITEMS_DELIVERY_FAILED = 'PENDING_ITEMS_DELIVERY_FAILED',
  ORDER_COLLECTION_FAILED = 'ORDER_COLLECTION_FAILED',
  EXTRA_ITEM_COLLECTION_FAILED = 'EXTRA_ITEM_COLLECTION_FAILED',
  ORDER_DELIVERY_FAILED = 'ORDER_DELIVERY_FAILED',
  ITEMS_REJECTED = 'ITEMS_REJECTED',
  CLAIMED_ITEM_DELIVERY_FAILED = 'CLAIMED_ITEM_DELIVERY_FAILED',
  ORDER_DELIVERY_ON_HOLD = 'ORDER_DELIVERY_ON_HOLD',
  CLAIMED_ITEM_COLLECTION_FAILED = 'CLAIMED_ITEM_COLLECTION_FAILED',
}
export enum OrderPickupAndDropoffTypes {
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
}

export declare module Order {
  export interface StainDamageApprovals {
    isAutoApprove: boolean;
  }

  export interface QueryParams {}

  export interface ItemsPackagedPerType {
    SHIRT: number;
  }

  export interface Packaging {
    hasCardboard: boolean;
    itemsPackagedPerType: ItemsPackagedPerType;
  }

  export interface DriverPickupInstructions {
    atDoorInstructionsArray: string[];
    inPersonInstructionsArray: string[];
  }

  export interface DriverDropoffInstructions {
    atDoorInstructionsArray: string[];
    inPersonInstructionsArray: string[];
  }

  export interface ShoeAddOnApprovals {
    restoration: boolean;
    stainProtection: boolean;
    soleIcing: boolean;
  }

  export interface Preferences {
    folding?: string;
    starch: string;
    creases?: string;
    stainDamageApprovals: StainDamageApprovals;
    packaging?: Packaging;
    driverPickupInstructions: DriverPickupInstructions;
    driverDropoffInstructions: DriverDropoffInstructions;
    shoeAddOnApprovals?: ShoeAddOnApprovals;
  }

  export interface SpecialAttentionItemsArray {
    imageName: string;
    brandName: string;
    stainsArray: string[];
    cleaningInstruction: string;
    tagsArray: string[];
  }

  export interface NewOrder {
    addressId: string;
    pickupDate: string;
    pickupTime: string;
    pickupTimeslotMode: string;
    dropoffTimeslotMode: string;
    isPickupAtDoor: boolean;
    isDropoffAtDoor: boolean;
    dropoffDate: string;
    dropoffTime: string;
    creditCardId?: string;
    applePayPayload?: any;
    driverTip: number;
    preferences?: Preferences;
    specialAttentionItemsArray?: SpecialAttentionItemsArray[];
    customOriginApp?: INTEGRATION_PARTNERS;
    careemInitialRecurringInvoiceId?: string;
    extraServiceLineData?: {
      serviceLine: ServiceLine;
      dropoffDate: string;
      dropoffTime: string;
      dropoffTimeslotMode: string;
      preferences?: Preferences;
    };
  }

  export interface UpdateOrder {
    pickupDate?: string;
    pickupTimeslotMode?: string;
    dropoffTimeslotMode?: string;
    pickupUserRangeStart?: number;
    pickupUserRangeEnd?: number;
    isPickupAtDoor?: boolean;
    isDropoffAtDoor?: boolean;
    dropoffDate?: string;
    dropoffTime?: string;
    dropoffUserRangeStart?: number;
    dropoffUserRangeEnd?: number;
    orderTaskId?: string;
  }

  export interface UpdatePickup {
    pickupDate: string;
    pickupTime: string;
    pickupTimeslotMode: string;
  }
  export interface UpdateDropoff {
    dropoffDate: string;
    dropoffTime: string;
    dropoffTimeslotMode: string;
  }

  export interface Address {
    id: string;
    addressType: string;
    addressText: string;
    number: string;
    lat: string;
    lng: string;
  }

  export interface Rating {
    starRating: number;
    issueTagsList?: string[];
    feedback?: string;
  }

  export interface PaginationMeta {
    currentPage: number;
    totalCount: number;
    totalPages: number;
    pageSize: number;
    lastEvaluatedId?: string;
  }

  export interface OrderHistory {
    datetime: string;
    id: string;
    name: string;
    status: string;
  }

  export interface DropoffAddressProps {
    addressText?: string;
    addressType?: string;
    street?: string;
    number?: string;
    lat?: string;
    lng?: string;
  }
  export interface PickupAddressProps {
    addressText: string;
    addressType: string;
    number: string;
    lat: string;
    lng: string;
  }
  interface NextPickupProps {
    isDefault: boolean;
    headerText: string;
    orderTaskId: string;
    pickupDate: string;
    pickupTime: string;
    pickupTimeslotMode: string;
    pickupAddress: PickupAddressProps;
    statusIdentifier: string;
    isPickupAtDoor: boolean;
    taskType?: string;
    pickupTimeslot: {
      slotId: string;
      date: string;
      startTimeInMinutes: number;
      endTimeInMinutes: number;
      selectedDateTimeText: string;
    };
  }

  interface NextDropoffProps {
    isDefault: boolean;
    headerText: string;
    orderTaskId: string;
    dropoffDate: string;
    dropoffTime: string;
    dropoffTimeslotMode: string;
    dropoffAddress: DropoffAddressProps;
    statusIdentifier: string;
    dropoffTimeslot: {
      slotId: string;
      date: string;
      startTimeInMinutes: number;
      endTimeInMinutes: number;
      selectedDateTimeText: string;
    };
    isDeliverAtDoor: boolean;
    taskType?: string;
    isDropoffConfirmed: boolean;
  }
  export interface Order {
    id: string;
    alphaId?: string;
    type: 'FLEX' | 'PRIORITY';
    serviceLine: string;
    statusLabel: OrderStatus;
    address: Address;
    creditCard: Cards.Card;
    preferencesDisplay: string[];
    pickupTimeslotMode: string;
    pickupDate: string;
    pickupTimeslot: TimeSlots.Timeslot;
    isPickupAtDoor: boolean;
    dropoffTimeslotMode: string;
    dropoffDate: string;
    dropoffTimeslot: TimeSlots.Timeslot;
    isDropoffAtDoor: boolean;
    typeText: string;
    statusHistory?: OrderHistory[];
    statusText: string;
    completionFormattedDate: string;
    progressBarNumber: number;
    netSale?: number;
    customerPortalToken: string;
    itemsApprovalCutoffTimestamp?: number;
    isPickupUpdatable: boolean;
    isDropoffUpdatable: boolean;
    isPaymentUpdatable: boolean;
    isCancelable: boolean;
    isPreferencesUpdatable: boolean;
    isPromoApplicationAllowed: boolean;
    isPickupAutoSelected?: boolean;
    nextPickup?: NextPickupProps;
    nextDropoff?: NextDropoffProps;
    completedTasks: Array<NextDropoffProps | NextPickupProps>;
    availableProofs: {
      collection: boolean;
      delivery: boolean;
      itemsReceived: boolean;
    };
    cancellationDate?: string;
    isPaymentFailed?: boolean;
    isNewPaymentFlow?: boolean;
    isShowInvoice?: boolean;
  }

  export interface ActivePastOrdersRootObject {
    orders: {
      activeOrders: Order[];
      pastOrders: Order[];
    };
  }
}

export enum Benefits {
  FREE_DELIVERY = 'FREE_DELIVERY',
}
