import { QueryClientProvider } from '@tanstack/react-query';
import Avo from 'analytics/Avo';
import { useDynamicFonts } from 'assets/fonts';
import { useDynamicIcons } from 'assets/icons';
import { useTheme } from 'assets/themes';
import { ConfirmationDialogProvider } from 'components/molecules/ConfirmationModal';
import { SpinnerLoader } from 'components/molecules/Loaders';
import ConfigProvider, { useConfig } from 'context/app-config';
import useCareemEvents from 'hooks/useCareemEvents';
import { useDynamicLanguage } from 'hooks/useDynamicLanguage';
import useImagePreloader from 'hooks/useImagePreloader';
import { usePartner } from 'hooks/usePartner';
import usePrevLocation from 'hooks/usePrevLocation';
import useSplashScreenDisplay from 'hooks/useSplashScreenDisplay';
import UpdatePickupOrDropoff from 'pages/orderDetails/components/update-pickup-dropoff';
import ActivePastOrders from 'pages/orders';
import Profile from 'pages/profile';
import { useGlobalConfig } from 'pages/queries/global.queries';
import { useCustomerPreferences } from 'pages/queries/user.queries';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import { ThemeProvider } from 'styled-components';
import useCreateAnimatableRoutes from 'utils/animation/hooks/useCreateAnimatableRoutes';
import RoutesTransitioner from 'utils/animation/RoutesTransitioner';
import { lazyWithPreload, withComponentPreloading } from 'utils/bundle';
import { queryClient } from 'utils/react-query';
import ProtectedRoutes from 'utils/router/ProtectedRoutes';
import { trackEvent } from 'wrappers/reporting';

const Home = lazyWithPreload(() => import('pages/home'));
const NewOrder = lazyWithPreload(() => import('pages/newOrder'));
const OrderDetails = lazyWithPreload(() => import('pages/orderDetails'));
const Pricing = lazyWithPreload(() => import('pages/pricing'));
const FineryPricingScreen = lazyWithPreload(() => import('pages/pricing/fineryPricingScreen'));
const Support = lazyWithPreload(() => import('pages/support'));
const Verify = lazyWithPreload(() => import('pages/verify'));
const Login = lazyWithPreload(() => import('pages/login'));

// load verify as this is the first page to load.
Verify.preload();

function usePageViews() {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  useEffect(() => {
    if (!location.search.includes('step')) {
      trackEvent(`Viewed ${location.pathname} Page`);
      Avo.pageview({ screen: location.pathname, previousScreen: prevLocation.pathname });
    }
  }, [location, prevLocation.pathname]);
}

function PreloadRequestsWithoutEffect() {
  const customer = useSelector(selectCustomer);

  if (!customer) {
    Login.preload();
  }

  // preloading customer information.
  // prevent re-render counts
  // TODO: handle this case for App Washmen.
  // useFetchCustomer();
  useGlobalConfig();
  useCustomerPreferences();

  usePageViews();

  useCareemEvents();
  useSplashScreenDisplay();

  usePartner();

  useConfig();

  return null;
}

const protectedRoutes = [
  {
    path: '/newOrder/*',
    Component: NewOrder,
  },
  {
    path: '/profile/*',
    Component: Profile,
  },
  {
    path: '/orders/*',
    Component: ActivePastOrders,
  },
  {
    path: '/order/details/:orderType/:orderId',
    Component: OrderDetails,
  },
  {
    path: '/order/details/:orderId',
    Component: OrderDetails,
  },
  {
    path: '/order/timeslots/:orderId',
    Component: UpdatePickupOrDropoff,
  },
];

const publicRoutes = [
  {
    path: '/home',
    Component: Home,
  },
  {
    path: '/support',
    Component: Support,
  },
  {
    path: '/verify',
    Component: Verify,
  },
  {
    path: '/verify/:token',
    Component: Verify,
  },
  {
    path: '/pricing/:type',
    Component: Pricing,
  },
  {
    path: '/pricing/THE_FINERY',
    Component: FineryPricingScreen,
  },
  {
    path: '/login/*',
    Component: Login,
  },
];

function RouterWrapper() {
  const { createAnimatableRoutes } = useCreateAnimatableRoutes();

  // TODO: enable={config?.shouldEnableRouteTransition} causing re-render
  // need to rethink the approach here

  const Routes = useMemo(
    () => (
      <RoutesTransitioner destroy skipIntialRoute="/home" timing="ease" animation="slide" enable>
        <Route element={<ProtectedRoutes />}>
          {createAnimatableRoutes(protectedRoutes.map(({ path, Component }) => <Route path={path} Component={Component} />))}
        </Route>

        <Route>{createAnimatableRoutes(publicRoutes.map(({ path, Component }) => <Route path={path} Component={Component} />))}</Route>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/reload" element={<Navigate to="/home" />} />
      </RoutesTransitioner>
    ),
    [createAnimatableRoutes, protectedRoutes, publicRoutes]
  );

  return (
    <>
      {Routes}
      <PreloadRequestsWithoutEffect />
    </>
  );
}

function App() {
  const theme = useTheme();

  useDynamicIcons();
  useDynamicFonts(theme);
  useImagePreloader();

  useDynamicLanguage();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfirmationDialogProvider>
          <ToastContainer hideProgressBar position="top-center" />
          <ConfigProvider>
            <React.Suspense fallback={<SpinnerLoader isDelayed />}>
              <Router>
                <RouterWrapper />
              </Router>
            </React.Suspense>
          </ConfigProvider>
        </ConfirmationDialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default withComponentPreloading({ components: [Home, NewOrder, Pricing, FineryPricingScreen, Support] })(App);
