export const onBlurScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const splitCommaSeparatedValues = (keys: string): string[] => {
  if (!keys) {
    return [];
  }

  return keys
    .split(',')
    .map((key) => key.trim().toLowerCase())
    .filter(Boolean); // This will remove any empty strings resulting from extra commas or spaces
};
